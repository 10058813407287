
















































































import { Component, Vue, Watch, PropSync } from "vue-property-decorator";
import { hist_clinico } from "@/shared/dtos/Historial_clinico/hist_clinico";
import hist_clinicoModule from "@/store/modules/historial_clinico/hist_clinico-module";
import { UtilsString } from "@/utils/utils-string";
@Component({
  components: {},
})
export default class HistClinicoCombo extends Vue {
  @PropSync("modeloetiquetas", { default: [] }) model!: hist_clinico[];
  @PropSync("id_paciente", { default: [] }) paciente!: number;
  @PropSync("id_tipohistclinico", { default: [] }) tipohistclinico!: number;
  public activator = null;
  public attach = null;
  public editing = null;
  public index = -1;
  public menu = false;
  public search = null;

  @Watch("model")
  public Wmodel(val: any, prev: any) {
    try {
      if (val.length === prev.length) return;
    } catch (error) {
      return;
    }

    this.model = val.map((v: any) => {
      if (typeof v === "string") {
        let eti: hist_clinico = new hist_clinico();
        eti.descripcion = v;
        eti.id_paciente = this.paciente;
        eti.id_tipohistclinico = this.tipohistclinico;
        let items = this.model.filter((x) => x.descripcion === eti.descripcion);
        if (items.length === 0) {
          hist_clinicoModule.guardarhist_clinico(eti);
        }
        v = eti;
      }
      return v;
    });
  }

  public eliminar(item: any) {
    if (!this.editing) {
      this.editing = item;
      //this.index = index;
      let index_delete = this.model.findIndex(
        (x) => x.descripcion === item.descripcion
      );
      hist_clinicoModule.eliminarhist_clinico_eliminar(
        this.model[index_delete]
      );
      this.model.splice(index_delete, 1);
      this.editing = null;
      this.index = -1;
    } else {
      this.editing = null;
      this.index = -1;
    }
  }

  public filter(item: any, queryText: string, itemText: any) {
    if (item.header) return false;

    const hasValue = (val: any) => (val != null ? val : "");

    const text = hasValue(itemText);
    const query = hasValue(queryText);

    return (
      text
        .toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    );
  }

  public cortarStringLargo(str: string) {
    return UtilsString.cortarStringLargo(str, 130);
  }
}
